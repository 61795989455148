<template>
  <div class="about-page relative">
    <!-- header section -->
    <div class="overflow-h">

      <div class="max-width min-height pt-10">
        <v-row align="center">
          <v-col cols="12" md="5" class="pb-0 relative person-section">
          <img
            class="header-bg"
            width="700px"
            :src="require('@/assets/images/header-bg.png')"
          />
          <img
          class="relative z-9"
            width="100%"
            :src="require('@/assets/images/about/about-banner.jpg')"
          />
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <div class="w-100">
            <span class="darktext--text font-800 font-88">
              {{ $t("aboutpage.aboutus") }}
            </span>
            <p class="font-24 mt-4 mb-10">
              {{ $t("aboutpage.headDesc") }}
            </p>
            <v-btn
              color="register-primary"
              elevation="0"
              width="240px"
              height="55px"
              rounded
              class="primary-btn-shadow mb-6"
              @click="
                $router.push(token ? '/dashboard/interviews' : '/register')
                "
            >
              <span class="white--text font-700 font-18">{{
                $t("aboutpage.perfectJob")
              }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="bottom-border"></div>
  </div>
    <img
      class="blur-img"
      :src="require('@/assets/images/head-blur-img.png')"
      alt=""
    />
    <!-- who we are -->
    <div class="max-width">
      <div class="line mx-auto register-primary mb-4 mt-14"></div>
      <div class="text-center">
        <span class="font-48 font-800 darktext--text">{{
          $t("aboutpage.whoWeAre")
        }}</span>
      </div>

      <!-- main text -->
      <v-row justify="center" class="mt-8">
        <v-col md="12" lg="12">
         
          <v-row
            class="mt-14"
            align="center"
            v-for="(section, i) in sections"
            :key="i"
            :class="section.reverse ? 'row-reverse' : ''"
          >
            <v-col cols="12" md="6">
              <div>
                <span class="font-48 font-700 register-primary--text d-block">{{
                  section.title
                }}</span>
                <p class="font-24 darktext--text mb-0"></p>
                <p class="font-24 darktext--text mb-0">
                  {{ section.desc }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <img
                class="mx-auto w-100"
                width="95%"
                :src="section.img"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="max-width mt-14 pt-14">
      <types-section></types-section>
    </div>
  </div>
</template>

<script>
import TypesSection from "../home/components/Types.vue";
export default {
  components: {
    TypesSection,
  },
  data: (vm) => ({
    sections: [
      {
        title: vm.$t("aboutpage.ourAim"),
        desc: vm.$t("aboutpage.ourAimDesc"),
        img: require("@/assets/images/about/aim.png"),
        reverse: false,
      },
      {
        title: vm.$t("aboutpage.ourVision"),
        desc: vm.$t("aboutpage.ourVisionDesc"),
        img: require("@/assets/images/about/vision.png"),
        reverse: true,
      },
      {
        title: vm.$t("aboutpage.ourMission"),
        desc: vm.$t("aboutpage.ourMissionDesc"),
        img: require("@/assets/images/about/mission.png"),
        reverse: false,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.about-page {
  margin-bottom: 16%;
  .min-height {
    min-height: unset;
    overflow: visible;
  }
}
</style>
